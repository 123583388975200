<template>
  <div>
    <template v-if="!success">
      <!--    付款页面-->
      <template v-if="showPay">
        <div style="background-color:#ff9900;text-align: center;color: #FFFFFF;font-size: 12px;">
          <div>订单剩余时间：{{ countData }}</div>
        </div>
        <div class="fs-20 padding-12 display-flex jc-center" style="color:rgb(51,51,51);font-weight: 700">订单详情</div>
        <p style="height:1px;background-color:rgb(241,241,241)"></p>
        <div class="pl-20 pr-20">
          <div class="padding-30 display-flex  fd-column ai-center jc-center ">
            <span class=" display-flex jc-center " style="color:#999999">{{ desc }}</span>
            <p class="fs-40" style="font-size: 40px;color:rgb(51,51,51);font-weight: 500">￥{{ price }}</p>
          </div>
          <div class="box">
            <p style="height:1px;background-color:rgb(241,241,241)"></p>
            <div class="display-flex ai-center jc-space-between" style="height: 50px;">
              <p>收款方</p>
              <p>江苏安胜达安全科技有限公司</p>
            </div>
            <p style="height:1px;background-color:rgb(241,241,241)"></p>
            <div class="display-flex ai-center jc-space-between" style="height: 50px;">
              <p>付款方式</p>
              <p>微信支付</p>
            </div>
            <p style="height:1px;background-color:rgb(241,241,241)"></p>
          </div>
          <div class="pb-30 pt-20">付款说明:<br/>1、付款前请务必确认自己所选培训班次类型，不同类型课程订单金额不同 <br/>
            <p style="color:red">2、切勿轻信各类刷单、保证金、退款、低价交易等信息、以免造成资金损失</p></div>
          <Button type="error" size="large" long @click="open">确认订单</Button>
        </div>
      </template>
      <!--    订单超时页面-->
      <div v-else class="padding-30 mt-30 display-flex fd-column ai-center">
        <Icon type="ios-alert" color="rgb(246,80,79)" size="60"/>
        <p class="fs-14 mt-10" style="color:rgb(246,80,79)">订单失效</p>
        <p class="pt-20 pb-20" style="color:#999999">当前订单已失效，请重新下单</p>
      </div>
    </template>
    <!--    成功页面-->
    <div v-else class="padding-30 mt-30 display-flex fd-column ai-center">
      <Icon type="ios-checkmark-circle" color="#19be6b" size="60"/>
      <p class="fs-14 mt-10" style="color:#19be6b">支付成功</p>
      <p class="pt-20 pb-20" style="color:#999999">报名成功</p>
    </div>
  </div>

</template>

<script>

import axios from 'axios'

export default {
  name: 'PaymentDetail',
  components: {},
  props: {},
  data () {
    return {
      success: false,
      pay_params: {
        appId: '',
        timeStamp: '',
        nonceStr: '',
        package: '',
        signType: '',
        paySign: ''
      },
      showPay: true, // 付款页面
      price: 0,
      desc: '',
      seconds: 0, // 5分钟
      timer: null // 定时器
    }
  },
  filters: {},
  computed: {
    countData () {
      let m = parseInt(this.seconds / 60 % 60)
      m = m < 10 ? `0${m}` : m
      let s = parseInt(this.seconds % 60)
      s = s < 10 ? `0${s}` : s
      return `${m}分${s}秒`
    }
  },
  watch: {},
  methods: {
    open () {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady)
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady)
        }
      } else {
        this.onBridgeReady(this.pay_params)
      }
    },
    onBridgeReady () {
      const _this = this
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        appId: this.pay_params.appId, // 公众号ID，由商户传入
        timeStamp: String(this.pay_params.timeStamp), // 时间戳，自1970年以来的秒数
        nonceStr: this.pay_params.nonceStr, // 随机串
        package: this.pay_params.package,
        signType: 'RSA', // 微信签名方式：
        paySign: this.pay_params.paySign
      },
      function (res) {
        _this.res = res
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          _this.success = true
          clearInterval(_this.timer)
        }
      })
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    axios.post(`https://peixun.asdsafe.cn:18001/wx/pay/code?recordId=${this.$route.query.recordId}`).then(res => {
      if (res.data.code === 200) {
        this.seconds = this.$moment(res.data.data.time_expire).unix() - this.$moment().unix()
        this.desc = res.data.data.description || '商品描述'
        this.price = res.data.data.total ? (res.data.data.total / 100).toFixed(2) : 0
        this.pay_params = {
          appId: res.data.data.appId,
          timeStamp: res.data.data.timeStamp,
          nonceStr: res.data.data.nonceStr,
          package: res.data.data.package,
          signType: res.data.data.signType,
          paySign: res.data.data.paySign
        }
        // 定时更新当前时间
        if (this.seconds > 0) {
          this.timer = setInterval(() => {
            if (this.seconds > 0) {
              this.seconds--
            } else {
              this.showPay = false
              clearInterval(this.timer)
            }
          }, 1000)
        } else {
          this.showPay = false
          this.seconds = 0
        }
      } else {
        this.$Message.warning('订单错误，请联系管理员')
      }
    })
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>

</style>
